import axios from "axios";
import { i18n } from "@/main";
const state = () => ({
  accessToken: null,
});

const getters = {
  accessToken: (state) => state.accessToken,
  accessTokenHeaderValue: (state) => {
    return "Bearer " + state.accessToken;
  },
};

const actions = {
  authAdmin({ rootState, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          rootState.apiAddress + "/auth/login",
          {},
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            auth: {
              username: payload.username,
              password: payload.password,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("setAccessToken", response.data.accessToken);
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.loggedIn"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  createTestUser({ rootState }) {
    return new Promise(() => {
      axios.post(rootState.apiAddress + "/auth/user");
    });
  },
  checkToken({ rootState, dispatch, commit, rootGetters }) {
    console.log(rootGetters["auth/accessTokenHeaderValue"]);
    commit("setTokenState");
    return new Promise((resolve, reject) => {
      axios
        .get(rootState.apiAddress + "/auth/verify", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: rootGetters["auth/accessTokenHeaderValue"],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  logoutAdmin({ commit, dispatch }) {
    dispatch(
      "showNotification",
      {
        message: i18n.t("notifications.loggedOut"),
        type: "error",
      },
      { root: true }
    );
    commit("setAccessToken", null);
  },
};

const mutations = {
  setAccessToken(state, payload) {
    sessionStorage.setItem("accessToken", payload);
    state.accessToken = payload;
  },
  setTokenState(state) {
    if (!state.accessToken) {
      state.accessToken = sessionStorage.getItem("accessToken");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
