import { i18n } from "@/main";
import axios from "axios";

const state = () => ({
  photosResponse: null,
});

const actions = {
  getPhotos({ rootState, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.apiAddress}/image/page/${payload.skip}`)
        .then((response) => {
          if (response.status === 200) {
            commit("setPhotos", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addPhoto({ rootState, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootState.apiAddress}/image/`,
          {
            product: payload.product,
            files: payload.photos,
            description: payload.product,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
              ContentType: payload.photos,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editPhoto({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${rootState.apiAddress}/image/${payload.id}`,
          {
            description: payload.description,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successPhotoEdit"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((err) => {
          dispatch(
            "showNotification",
            { message: err.message, type: "error" },
            { root: true }
          );
          reject();
        });
    });
  },
  deletePhoto({ rootState, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootState.apiAddress}/image/${payload.filename}`, {
          headers: {
            Authorization: rootGetters["auth/accessTokenHeaderValue"],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setPhotos(state, payload) {
    state.photosResponse = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
