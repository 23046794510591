import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { LoadingProgrammatic as Loading } from "buefy";
const HomeView = () => import("../views/HomeView.vue");
const GalleryView = () => import("@/views/GalleryView.vue");
const LinksView = () => import("@/views/LinksView.vue");
const OrderingView = () => import("@/views/OrderingView.vue");
const ItemsView = () => import("@/views/ProductsView.vue");
const NewsView = () => import("@/views/NewsView.vue");
const IndexAdmin = () => import("@/views/admin/IndexAdmin.vue");
const ItemsAdmin = () => import("@/views/admin/ItemsAdmin.vue");
const CategoriesAdmin = () => import("@/views/admin/CategoriesAdmin.vue");
const NewsAdmin = () => import("@/views/admin/NewsAdmin.vue");
const GalleryAdmin = () => import("@/views/admin/GalleryAdmin.vue");
const BannersAdmin = () => import("@/views/admin/BannersAdmin.vue");
const Login = () => import("@/views/admin/Login.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
  },
  {
    path: "/links",
    name: "links",
    component: LinksView,
  },
  {
    path: "/ordering",
    name: "ordering",
    component: OrderingView,
  },
  {
    path: "/admin",
    params: { origin: "index" },
    name: "admin",
    component: IndexAdmin,
  },
  {
    path: "/news",
    name: "news",
    component: NewsView,
  },
  {
    path: "/admin/items/:origin",
    name: "adminItems",
    component: ItemsAdmin,
  },
  {
    path: "/admin/categories",
    name: "adminCategories",
    component: CategoriesAdmin,
  },
  {
    path: "/admin/news",
    name: "adminNews",
    component: NewsAdmin,
  },
  {
    path: "/admin/gallery",
    name: "adminGallery",
    component: GalleryAdmin,
  },
  {
    path: "/admin/banners",
    name: "adminBanners",
    component: BannersAdmin,
  },
  {
    path: "/auth/login",
    name: "adminLogin",
    component: Login,
  },
  {
    path: "/items/:origin",
    name: "items",
    component: ItemsView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let loading;

router.beforeEach(async (to, from, next) => {
  loading = Loading.open({ container: null, isFullPage: true });
  if (to.path.includes("/admin")) {
    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      loading.close();
      next("/auth/login");
      return;
    }
    if (token) {
      return store
        .dispatch("auth/checkToken", { token: token })
        .then(() => {
          next();
        })
        .catch(() => {
          loading.close();
          next("/auth/login");
        });
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  loading.close();
});

export default router;
