import { i18n } from "@/main";
import axios from "axios";

const state = () => ({
  categories: null,
});

const actions = {
  getCategories({ rootState, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.apiAddress}/categories/${payload.origin}`)
        .then((response) => {
          if (response.status === 200) {
            commit("setCategories", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addCategory({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootState.apiAddress}/categories/` + payload.origin,
          {
            title: payload.title,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successCategoryAdd"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  editCategory({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${rootState.apiAddress}/categories/${payload.id}`,
          {
            title: payload.title,
            origin: payload.origin,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successCategoryEdit"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  deleteCategory({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${rootState.apiAddress}/categories/${payload.origin}/${payload.id}`,
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successCategoryDelete"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
};
const mutations = {
  setCategories(state, payload) {
    state.categories = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
