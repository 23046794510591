import { i18n } from "@/main";
import axios from "axios";

const state = () => ({
  news: null,
});

const actions = {
  getNews({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.apiAddress}/news/`)
        .then((response) => {
          if (response.status === 200) {
            commit("setNews", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addNews({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootState.apiAddress}/news/`,
          {
            message: payload.message,
            timestamp: payload.timestamp,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successNewsAdd"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  editNews({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${rootState.apiAddress}/news/` + payload.id,
          {
            message: payload.message,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successNewsEdit"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  deleteNews({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootState.apiAddress}/news/` + payload.id, {
          headers: {
            Authorization: rootGetters["auth/accessTokenHeaderValue"],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successNewsDelete"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "success",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
};

const mutations = {
  setNews(state, payload) {
    state.news = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
