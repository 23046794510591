import { i18n } from "@/main";
import axios from "axios";

const state = () => ({
  banners: [],
});

const actions = {
  getBanners({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(rootState.apiAddress + "/banner/", {})
        .then((response) => {
          if (response.status === 200) {
            commit("setBanners", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addBanner({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          rootState.apiAddress + "/banner/",
          {
            link: payload.link,
            website: payload.website,
            description: payload.description,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successBannerAdd"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  editBanner({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          rootState.apiAddress + "/banner/" + payload.id,
          {
            link: payload.link,
            website: payload.website,
            description: payload.description,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successBannerEdit"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  deleteBanner({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(rootState.apiAddress + "/banner/" + payload.id, {
          headers: {
            Authorization: rootGetters["auth/accessTokenHeaderValue"],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successBannerDelete"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
};

const mutations = {
  setBanners(state, payload) {
    state.banners = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
