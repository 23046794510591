import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Upload, Image, Carousel, CarouselItem } from "element-ui";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import "element-ui/lib/theme-chalk/index.css";
import Buefy from "buefy";
import "@/assets/styles/main.scss";
import "@mdi/font/css/materialdesignicons.css";
import VueI18n from "vue-i18n";
import json from "@/helpers/i18n.json";

locale.use(lang);

Vue.config.productionTip = false;
Vue.use(CarouselItem);
Vue.use(Carousel);
Vue.use(Upload);
Vue.use(Image);
Vue.use(Buefy);
Vue.use(VueI18n);

const messages = json;

export const i18n = new VueI18n({
  locale: "ru",
  fallbackLocale: "en",
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
