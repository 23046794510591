import { i18n } from "@/main";
import axios from "axios";

const state = () => ({
  products: [],
  itemCount: [],
  singleProduct: [],
  pinnedProducts: [],
  productResponse: null,
});

const actions = {
  getProductCount({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.apiAddress}/stats`)
        .then((response) => {
          if (response.status === 200) {
            commit("setCount", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProducts({ rootState, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.apiAddress}/products`, {
          params: {
            origin: payload.origin,
            category: payload.category,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("setProducts", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllProducts({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.apiAddress}/products/`)
        .then((response) => {
          if (response.status === 200) {
            commit("setProducts", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSingleProduct({ rootState, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.apiAddress}/products/` + payload.id)
        .then((response) => {
          if (response.status === 200) {
            commit("setSingleProduct", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPinnedProducts({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootState.apiAddress}/products?origin=pinned`)
        .then((response) => {
          if (response.status === 200) {
            commit("setPinnedProducts", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveProduct({ rootState, commit, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootState.apiAddress}/products`,
          {
            title: payload.title,
            description: payload.description,
            price: payload.price,
            currency: payload.currency,
            status: payload.status,
            category: payload.category,
            newItem: payload.newItem,
            pinned: payload.pinned,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successProductAdd"),
                type: "success",
              },
              { root: true }
            );
            commit("setProductResponse", response.data);
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  editProduct({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${rootState.apiAddress}/products/` + payload.id,
          {
            title: payload.title,
            description: payload.description,
            price: payload.price,
            currency: payload.currency,
            status: payload.status,
            category: payload.category,
            newItem: payload.newItem,
            pinned: payload.pinned,
          },
          {
            headers: {
              Authorization: rootGetters["auth/accessTokenHeaderValue"],
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successProductEdit"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
  deleteProduct({ rootState, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootState.apiAddress}/products/` + payload.id, {
          headers: {
            Authorization: rootGetters["auth/accessTokenHeaderValue"],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              "showNotification",
              {
                message: i18n.t("notifications.successProductDelete"),
                type: "success",
              },
              { root: true }
            );
            resolve();
          }
        })
        .catch((error) => {
          dispatch(
            "showNotification",
            {
              message: error.message,
              type: "error",
            },
            { root: true }
          );
          reject(error);
        });
    });
  },
};

const mutations = {
  setProducts(state, payload) {
    state.products = payload;
  },
  setSingleProduct(state, payload) {
    state.singleProduct = payload;
  },
  setCount(state, payload) {
    state.itemCount = payload;
  },
  setProductResponse(state, payload) {
    state.productResponse = payload;
  },
  setPinnedProducts(state, payload) {
    state.pinnedProducts = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
