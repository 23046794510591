<template>
  <div id="app">
    <Navbar />
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script lang="js">
import {mapActions, mapMutations, mapGetters} from "vuex";
import Navbar from "@/components/layout/Navbar.vue";

export default {
    created() {
        if(!sessionStorage.getItem("demjanskAgreement")) {
            this.$buefy.dialog.alert({
                title: "Disclaimer",
                message: "This site and DBM & C does not support politics and ideology of the Third Reich and its leaders. All items are sold as collectable items and are not sold for actual use. Demjansk Battlefield militaria & collectibles. ",
                type: "is-danger",
                hasIcon: true,
                ariaModal: true,
                confirmText: "I understand, continue",
                onConfirm: (()=> {
                    sessionStorage.setItem("demjanskAgreement", true);
                })
            })
        }
    },
    methods: {
        ...mapMutations("auth", ["getAccessToken"]),
        ...mapActions("auth", ["checkToken"]),
        getCurrentYear() {
            return new Date().getFullYear();
        },
    },
    computed: {
        ...mapGetters("auth", ["accessToken", "accessTokenHeaderValue"]),
    },
    components: { Navbar }
};
</script>
