import Vue from "vue";
import Vuex from "vuex";
import { NotificationProgrammatic as Notification } from "buefy";
import products from "@/store/modules/products.js";
import categories from "@/store/modules/categories.js";
import photos from "@/store/modules/photos.js";
import news from "@/store/modules/news.js";
import banners from "@/store/modules/banners.js";
import auth from "@/store/modules/auth.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    apiAddress: "http://api.relictsshop.com",
  }),
  getters: {},
  actions: {
    showNotification(_, payload) {
      const notifications = {
        success: "is-success",
        error: "is-danger",
      };
      Notification.open({
        message: payload.message,
        type: notifications[payload.type],
        duration: 5000,
        closable: false,
        progressBar: true,
        hasIcon: true,
      });
    },
  },
  mutations: {},
  modules: {
    products,
    categories,
    photos,
    news,
    banners,
    auth,
  },
});
