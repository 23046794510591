<template>
  <b-navbar fixed-top centered>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="@/assets/svg/logo.svg" alt="" class="logo__text" />
      </b-navbar-item>
    </template>
    <template
      #start
      v-if="!this.$route.matched.some((route) => route.path.includes('/admin'))"
    >
      <b-navbar-item tag="router-link" :to="{ path: '/' }"
        ><span class="nav-item-text">Home</span></b-navbar-item
      >
      <b-navbar-dropdown label="Items">
        <b-navbar-item tag="router-link" :to="{ path: '/items/german' }"
          >German</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ path: '/items/soviet' }"
          >Soviet</b-navbar-item
        >
      </b-navbar-dropdown>
      <b-navbar-item tag="router-link" :to="{ path: '/gallery' }"
        ><span class="nav-item-text">Gallery</span></b-navbar-item
      >
      <b-navbar-item tag="router-link" :to="{ path: '/ordering' }"
        ><span class="nav-item-text">Ordering</span></b-navbar-item
      >
      <b-navbar-item tag="router-link" :to="{ path: '/news' }"
        ><span class="nav-item-text">News</span></b-navbar-item
      >
      <b-navbar-item tag="router-link" :to="{ path: '/links' }"
        ><span class="nav-item-text">Links</span></b-navbar-item
      >
    </template>

    <!-- ADMIN -->
    <template
      #start
      v-else-if="
        this.$route.matched.some((route) => route.path.includes('/admin'))
      "
    >
      <b-navbar-item tag="router-link" :to="{ path: '/admin' }">{{
        $t("navbar.home")
      }}</b-navbar-item>
      <b-navbar-dropdown :label="$t('navbar.items')" :collapsible="true">
        <b-navbar-item tag="router-link" :to="{ path: '/admin/items/all' }">{{
          $t("navbar.all")
        }}</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/admin/items/german' }"
          >German</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ path: '/admin/items/soviet' }"
          >Soviet</b-navbar-item
        >
      </b-navbar-dropdown>
      <b-navbar-item tag="router-link" :to="{ path: '/admin/categories' }">
        {{ $t("navbar.categories") }}
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/admin/gallery' }">{{
        $t("navbar.gallery")
      }}</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/admin/news' }">{{
        $t("navbar.news")
      }}</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/admin/banners' }">{{
        $t("navbar.banners")
      }}</b-navbar-item>
    </template>
    <template
      #end
      v-if="this.$route.matched.some((route) => route.path.includes('/admin'))"
    >
      <b-navbar-item>
        <b-select icon="earth" v-model="$i18n.locale">
          <option value="en">Eng</option>
          <option value="ru">Rus</option>
        </b-select>
      </b-navbar-item>
      <b-navbar-item
        ><b-button
          type="is-primary"
          :label="$t('navbar.backtosite')"
          @click="backToSite()"
      /></b-navbar-item>
      <b-navbar-item
        ><b-button
          type="is-danger is-responsive"
          :label="$t('navbar.logout')"
          class="is-responsive"
          @click="_logoutAdmin()"
          icon-left="logout"
      /></b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "nav-bar",
  data() {
    return {
      activeIndex: this.$route.path,
    };
  },
  methods: {
    ...mapActions("auth", ["logoutAdmin"]),
    _logoutAdmin() {
      this.logoutAdmin().then(() => {
        this.$router.push("/");
        this.$buefy.notification.open({
          duration: 2000,
          message: "Logged out!",
          type: "is-success",
          hasIcon: true,
        });
      });
    },
    backToSite() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped></style>
